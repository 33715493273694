import { useQuery } from '@redwoodjs/web'
import { useState } from 'react'
import { GET_EMPLOYEES } from 'src/graphql/employees.graphql'
import StyledMultiselect from 'src/components/StyledMultiselect/StyledMultiselect'

const RecruitersMultiselect = ({
  selectedRecuiters = [],
  handleChange,
  ...props
}) => {
  const [chosenRecruiters, setChosenRecruiters] = useState(selectedRecuiters)
  /**********************    QUERIES    **********************/
  const { data: employees, loading: employeesLoading } = useQuery(
    GET_EMPLOYEES,
    {
      variables: {
        filters: { roleTypes: 'RECRUITER' },
      },
    }
  )
  const recruiters = employeesLoading
    ? []
    : employees?.employees?.items?.map((employee) => ({
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.employeeId,
      })) ?? []

  return (
    <StyledMultiselect
      data={recruiters}
      value={chosenRecruiters}
      placeholder={chosenRecruiters?.length > 0 ? '' : 'Recruiters'}
      onChange={(value) => {
        handleChange(value)
        setChosenRecruiters(value)
      }}
      {...props}
    />
  )
}

export default RecruitersMultiselect
