import { AreaChart } from '@mantine/charts'
import { sampleData } from './sampleData'
import { sampleSeries } from './sampleData'
import AreaChartTooltip from './AreaChartTooltip'

const StyledAreaChart = ({
  useSampleData = true,
  series = [],
  data = [],
  ...props
}) => {
  return (
    <AreaChart
      h={300}
      data={useSampleData ? sampleData : data}
      dataKey="date"
      fillOpacity={0.4}
      series={useSampleData ? sampleSeries : series}
      withLegend
      legendProps={{
        verticalAlign: 'bottom',
      }}
      yAxisProps={{
        allowDecimals: false,
        padding: {
          top: 20,
        },
      }}
      withGradient
      withDots
      classNames={{
        legendItem: 'flex flex-row gap-2 items-center',
        legend:
          'flex flex-row gap-6 pt-8 items-center justify-end text-sm text-doubleNickel-gray-700',
      }}
      tooltipProps={{
        content: ({ label, payload }) => (
          <AreaChartTooltip label={label} payload={payload} />
        ),
      }}
      {...props}
    />
  )
}

export default StyledAreaChart
