export const LeadConversionData = [
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f1',
    leadSource: 'Facebook',
    leads: 14,
    applications: 12,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
    leadToApplication: '85%',
    leadToHire: '14%',
    leadToDisqualified: '0%',
    leadToNotInterested: '42%',
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f2',
    leadSource: 'Company Website',
    leads: 14,
    applications: 12,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
    leadToApplication: '85%',
    leadToHire: '14%',
    leadToDisqualified: '0%',
    leadToNotInterested: '42%',
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f3',
    leadSource: 'Recruiter',
    leads: 14,
    applications: 12,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
    leadToApplication: '85%',
    leadToHire: '14%',
    leadToDisqualified: '0%',
    leadToNotInterested: '42%',
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f4',
    leadSource: 'Referral',
    leads: 14,
    applications: 12,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
    leadToApplication: '85%',
    leadToHire: '14%',
    leadToDisqualified: '0%',
    leadToNotInterested: '42%',
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f5',
    leadSource: 'Truckers Report',
    leads: 14,
    applications: 12,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
    leadToApplication: '85%',
    leadToHire: '14%',
    leadToDisqualified: '0%',
    leadToNotInterested: '42%',
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f6',
    leadSource: 'Truck Drivers USA',
    leads: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
    leadToApplication: '85%',
    leadToHire: '14%',
    leadToDisqualified: '0%',
    leadToNotInterested: '42%',
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f7',
    leadSource: 'Randal Riley',
    leads: 14,
    applications: 12,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
    leadToApplication: '85%',
    leadToHire: '14%',
    leadToDisqualified: '0%',
    leadToNotInterested: '42%',
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f8',
    leadSource: 'Linkedin',
    leads: 14,
    applications: 12,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
    leadToApplication: '85%',
    leadToHire: '14%',
    leadToDisqualified: '0%',
    leadToNotInterested: '42%',
  },
]

export const LeadConversionColumns = [
  { accessor: 'leadSource' },
  { accessor: 'leads' },
  { accessor: 'applications' },
  { accessor: 'notInterested' },
  { accessor: 'disqualified' },
  { accessor: 'hired' },
  { accessor: 'terminated' },
  { accessor: 'leadToApplication' },
  { accessor: 'leadToHire' },
  { accessor: 'leadToDisqualified' },
  { accessor: 'leadToNotInterested' },
]
