import { Grid } from '@mantine/core'
import { Metadata, useQuery } from '@redwoodjs/web'
import AnalyticsCard from './components/AnalyticsCard'
import { useState } from 'react'
import LeadConversionTable from './components/LeadConversionTable'
import { GET_ACTIVITY_STATUS_LOG_METRICS } from 'src/graphql/statusLogs.graphql'
import RecruitersMultiselect from './components/RecruitersMultiselect'
import JobListingsMultiselect from './components/JobListingsMultiselect'
import LeadSourceMultiselect from './components/LeadSourceMultiselect'
import DateRangeMenu from './components/DateRangeMenu'
import PipelineAreaChart from './components/PipelineAreaChart'
import SparklineCard from './components/SparklineCard'
import dayjs from 'dayjs'
import { generateMetricsByDateRange } from './utils'
import DispositionTable from './components/DispositionTable'
import { useAuth } from 'src/auth'

const AnalyticsPage = () => {
  const recruitersUrlString = new URLSearchParams(window.location.search).get(
    'recruiters'
  )
  const selectedRecruitersFromUrl = recruitersUrlString
    ? recruitersUrlString.split(',')
    : []

  const leadSourcesUrlString = new URLSearchParams(window.location.search).get(
    'leadSources'
  )
  const selectedLeadSourcesFromUrl = leadSourcesUrlString
    ? leadSourcesUrlString.split(',')
    : []
  const jobListingsUrlString = new URLSearchParams(window.location.search).get(
    'jobListings'
  )
  const selectedJobListingsFromUrl = jobListingsUrlString
    ? jobListingsUrlString.split(',')
    : []

  const dateRangeUrlString = new URLSearchParams(window.location.search).get(
    'dateRange'
  )
  const dateRange = dateRangeUrlString
    ? dateRangeUrlString.split(',')
    : [dayjs().subtract(30, 'day').startOf('day').toDate(), new Date()]
  const defaultStartDate = dateRange[0]
  const defaultEndDate = dateRange[1]
  const [selectedRecruiters, setSelectedRecruiters] = useState(
    selectedRecruitersFromUrl
  )
  const [selectedLeadSources, setSelectedLeadSources] = useState(
    selectedLeadSourcesFromUrl
  )
  const [selectedJobListings, setSelectedJobListings] = useState(
    selectedJobListingsFromUrl
  )
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [showCalendar, setShowCalendar] = useState(false)
  const [areaChartType, setAreaChartType] = useState('default')

  const { currentUser, loading } = useAuth()
  const isAdmin = currentUser && currentUser.roles.includes('ADMIN')
  /**********************    QUERIES    **********************/

  const {
    data: activityStatusLogMetrics,
    loading: activityStatusLogMetricsLoading,
  } = useQuery(GET_ACTIVITY_STATUS_LOG_METRICS, {
    variables: {
      filters: {
        recruiters: selectedRecruiters,
        jobListings: selectedJobListings,
        linkTypes: selectedLeadSources,
        dateRange: {
          gte: startDate,
          lte: endDate,
        },
      },
    },
  })

  const updateUrl = (name, value) => {
    const url = new URL(window.location.href)
    if (!value) {
      url.searchParams.delete(name)
    } else {
      url.searchParams.set(name, value)
    }
    window.history.replaceState(null, null, url)
  }

  const pipelineData = generateMetricsByDateRange(
    startDate,
    endDate,
    activityStatusLogMetrics?.activityStatusLogMetrics || []
  )

  return (
    <div className="flex h-full flex-col gap-4 overflow-hidden px-5 pb-5">
      <Metadata title="Analytics" description="Analytics page" />
      <div className="text-lg font-semibold text-doubleNickel-gray-900">
        Pipeline Analytics
      </div>
      <div className="flex flex-row items-center gap-4">
        <JobListingsMultiselect
          selectedJobListings={selectedJobListings}
          handleChange={(value) => {
            setSelectedJobListings(value)
            updateUrl('jobListings', value.join(','))
          }}
        />
        <LeadSourceMultiselect
          selectedLeadSource={selectedLeadSources}
          handleChange={(value) => {
            setSelectedLeadSources(value)
            updateUrl('leadSources', value.join(','))
          }}
        />
        <RecruitersMultiselect
          selectedRecuiters={selectedRecruiters}
          handleChange={(value) => {
            setSelectedRecruiters(value)
            updateUrl('recruiters', value.join(','))
          }}
        />
        <div className="ml-auto">
          <DateRangeMenu
            startDate={startDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            endDate={endDate}
            showCalendar={showCalendar}
            setShowCalendar={setShowCalendar}
            handleChange={(value) => {
              updateUrl('dateRange', value.join(','))
            }}
          />
        </div>
      </div>
      <Grid className="h-full overflow-auto">
        <Grid.Col span={4}>
          <AnalyticsCard
            title="Pipeline"
            loading={activityStatusLogMetricsLoading}
            description={'Total prospects in the pipeline'}
          >
            <SparklineCard pipelineMetrics={pipelineData} />
          </AnalyticsCard>
        </Grid.Col>
        <Grid.Col span={8}>
          <AnalyticsCard
            title="Pipeline Activity"
            loading={activityStatusLogMetricsLoading}
            description={
              'This shows how many applicants were moved into each stage of the pipeline over time'
            }
          >
            <PipelineAreaChart type={areaChartType} data={pipelineData} />
          </AnalyticsCard>
        </Grid.Col>
        {isAdmin && (
          <>
            <Grid.Col span={12}>
              <AnalyticsCard
                title="Lead Conversion"
                badgeText="Sample data"
                showBadge
                description={'Lead conversion and ratio metrics'}
              >
                <LeadConversionTable />
              </AnalyticsCard>
            </Grid.Col>
            <Grid.Col span={12}>
              <AnalyticsCard
                title="Disposition Analysis"
                badgeText="Sample data"
                showBadge
                description={'Disposition analysis and metrics'}
              >
                <DispositionTable />
              </AnalyticsCard>
            </Grid.Col>
          </>
        )}
      </Grid>
    </div>
  )
}

export default AnalyticsPage
