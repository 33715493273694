export const sampleData = [
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f1',
    leadSource: 'Facebook',
    prospects: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f2',
    leadSource: 'Company Website',
    prospects: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f3',
    leadSource: 'Recruiter',
    prospects: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f4',
    leadSource: 'Referral',
    prospects: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f5',
    leadSource: 'Truckers Report',
    prospects: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f6',
    leadSource: 'Truck Drivers USA',
    prospects: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f7',
    leadSource: 'Randal Riley',
    prospects: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f8',
    leadSource: 'Linkedin',
    prospects: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
  },
  {
    id: '1323addd-a4ac-4dd2-8de2-6f934969a0f9',
    leadSource: 'Intentionally long name to test overflow',
    prospects: 14,
    notInterested: 6,
    disqualified: 0,
    hired: 2,
    terminated: 3,
    testOverflow: 1,
  },
]

export const sampleColumns = [
  { accessor: 'leadSource' },
  { accessor: 'prospects' },
  { accessor: 'notInterested' },
  { accessor: 'disqualified' },
  { accessor: 'hired' },
  { accessor: 'terminated' },
]
