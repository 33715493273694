import StyledMantineDataTable from './StyledDataTable/StyledMantineDataTable'
import { LeadConversionColumns, LeadConversionData } from '../sampleData'

const DispositionTable = ({}) => {
  return (
    <StyledMantineDataTable
      useSampleData={false}
      columns={LeadConversionColumns}
      data={LeadConversionData}
    />
  )
}

export default DispositionTable
