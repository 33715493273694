import StyledMantineDataTable from './StyledDataTable/StyledMantineDataTable'
import { LeadConversionColumns, LeadConversionData } from '../sampleData'

const LeadConversionTable = ({}) => {
  return (
    <div className="w-full overflow-auto">
      <StyledMantineDataTable
        useSampleData={false}
        columns={LeadConversionColumns}
        data={LeadConversionData}
      />
    </div>
  )
}

export default LeadConversionTable
