import { sampleColumns, sampleData } from './sampleData'
import { useState } from 'react'
import { Table } from '@mantine/core'
import { formatCamelValue } from 'src/lib/formatters'
import { cn } from 'src/utils'

const StyledMantineDataTable = ({
  useSampleData = true,
  columns = [],
  data = [],
  handleRowClick = (record) => {},
  ...props
}) => {
  const columnData = useSampleData ? sampleColumns : columns
  const rowData = useSampleData ? sampleData : data
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [hoveredRecord, setHoveredRecord] = useState(null)

  const tableCols = columnData.map((element, index) => (
    <Table.Th
      key={element.accessor + index}
      className="text-nowrap bg-doubleNickel-gray-50 py-4 font-semibold text-doubleNickel-gray-600"
    >
      {formatCamelValue(element.accessor)}
    </Table.Th>
  ))

  const tableRows = rowData.map((element, index) => (
    <Table.Tr
      key={element.id + index}
      className={cn(
        selectedRecord?.id === element.id &&
          'bg-doubleNickel-brand-25 text-doubleNickel-brand-600'
      )}
      onMouseEnter={() => {
        handleRowClick(element)
        setHoveredRecord(element)
      }}
      onMouseLeave={() => setHoveredRecord(null)}
      onClick={() => {
        if (selectedRecord?.id === element.id) {
          setSelectedRecord(null)
          handleRowClick(null)
        } else {
          setSelectedRecord(element)
          handleRowClick(element)
        }
      }}
    >
      {columnData.map((column, index) => (
        <Table.Td key={index}>{element[column.accessor]}</Table.Td>
      ))}
    </Table.Tr>
  ))

  return (
    <Table.ScrollContainer minWidth={200}>
      <Table highlightOnHover stickyHeader {...props}>
        <Table.Thead>
          <Table.Tr>{tableCols}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>{tableRows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default StyledMantineDataTable
