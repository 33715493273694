import dayjs from 'dayjs'

//Transform the metrics array into a format that can be used by the chart library - function courtesy of ChatGPT
export const generateMetricsByDateRange = (startDate, endDate, metrics) => {
  // Helper function to create a date range between two dates
  function createDateRange(start, end) {
    const dateArray = []
    let currentDate = new Date(start)
    end = new Date(end)

    while (currentDate <= end) {
      dateArray.push(new Date(currentDate).toISOString().split('T')[0]) // Store only the date part
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateArray
  }

  // Helper function to initialize the default object
  function getDefaultMetricsObject(date) {
    return {
      date: dayjs(date).format('MM/DD'),
      Prospect: 0,
      Terminated: 0,
      Hired: 0,
      'Not Interested': 0,
      Disqualified: 0,
    }
  }

  // Create date range
  const dateRange = createDateRange(startDate, endDate)

  // Create a map to store the metrics by date
  const metricsMap = {}

  // Iterate over the metrics array and populate the metricsMap
  metrics.forEach((metric) => {
    const metricDate = metric.date.split('T')[0] // Only use the date part
    if (!metricsMap[metricDate]) {
      metricsMap[metricDate] = getDefaultMetricsObject(metricDate)
    }

    // Update the count based on the applicant stage
    switch (metric.applicantStage) {
      case 'PROSPECT':
        metricsMap[metricDate]['Prospect'] += metric.count
        break
      case 'TERMINATED':
        metricsMap[metricDate]['Terminated'] += metric.count
        break
      case 'HIRED':
        metricsMap[metricDate]['Hired'] += metric.count
        break
      case 'NOT_INTERESTED':
        metricsMap[metricDate]['Not Interested'] += metric.count
        break
      case 'DISQUALIFIED':
        metricsMap[metricDate]['Disqualified'] += metric.count
        break
    }
  })

  // Generate the final array with default metrics for missing dates
  return dateRange.map((date) => {
    return metricsMap[date] || getDefaultMetricsObject(date)
  })
}
